import { ActionType } from './../constants';
const initialState = {
  status: null,
  jobId: null,
  userId: null,
  message: '',
};
const JobReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.CUSTOMER_SYNC_JOB_CREATION_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.CUSTOMER_SYNC_JOB_CREATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobCreationResponseReceived: true,
        displayJobCreationNotification: true,
        jobInfo: action.payload.jobData,
      };

    case ActionType.CUSTOMR_SYNC_JOB_CREATION_ERROR:
      return {
        ...state,
        isLoading: false,
        jobCreationResponseReceived: true,
        displayJobCreationNotification: true,
        jobInfo: action.payload,
      };
    case ActionType.CLOSE_JOB_CREATION_NOTIFICATION_ALERT:
      return {
        ...state,
        displayJobCreationNotification: action.payload.displayJobCreationNotification,
      };
    default:
      return state;
  }
};

export { JobReducer };
