import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SideMenu } from '../components/SideMenu';
import { Footer } from '../components/Footer';
import { ErrorBoundary } from '../components/ErrorBoundary';
import decode from 'jwt-decode';
import { config } from '../constants';
import { getJobsStatus } from '../helpers/PostDataApi';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { removeCompletedJobsFromPollingList } from './commonFunction';

const checkAuth = () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  if (!token || !refreshToken) {
    return false;
  }
  try {
    const { exp } = decode(token);
    if (exp < new Date().getTime() / 1000) {
      localStorage.removeItem('user');
      localStorage.removeItem('locationId', { path: '/' });
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

const getPermission = (props) => {
  const userData = JSON.parse(localStorage.getItem('user'));
  if (userData) {
    const route = props.match.path.split('/')[1];
    if (route === 'Settings') {
      if (
        userData?.permissions.feeEdit === true &&
        (userData?.role === config.REGULAR_USER_ROLE || userData?.role === config.MANAGER_ROLE)
      ) {
        return true;
      }
    }
    if (userData.role === config.CUSTOMER_USER_ROLE) {
      if (route === 'Customer') {
        return true;
      } else {
        return false;
      }
    }
    return userData?.permissions['display' + route] ?? true;
  }
  return true;
};
const notifyCompletedJobs = (completedJobs) => {
  completedJobs.forEach((completedJob) => {
    enqueueSnackbar(`${completedJob.jobType} ${completedJob._id} ${completedJob.status}`, {
      variant: completedJob.status === 'completed' ? 'success' : 'error',
      persist: false,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
    });
  });
};
const fetchJobsStatus = async () => {
  let jobIds = localStorage.getItem('createdJobs') && JSON.parse(localStorage.getItem('createdJobs'));
  if (jobIds?.length > 0) {
    let completedJobs = await getJobsStatus(JSON.stringify(jobIds));
    if (completedJobs) {
      completedJobs = completedJobs.filter((job) => ['completed', 'failed'].includes(job.status));

      notifyCompletedJobs(completedJobs);
      removeCompletedJobsFromPollingList(completedJobs.map((completedJob) => completedJob._id));
    }
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    const intervalId = setInterval(fetchJobsStatus, 10000);
    setIntervalId(intervalId);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const [inveralId, setIntervalId] = useState('');
  return (
    <Route
      {...rest}
      render={(props) =>
        checkAuth() && getPermission(props) ? (
          <>
            <ErrorBoundary>
              <div className="wrapper">
                <SnackbarProvider />
                <SideMenu />
                <Component {...props} />
              </div>
              <Footer />
            </ErrorBoundary>
          </>
        ) : (
          <ErrorBoundary>
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          </ErrorBoundary>
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};
export default PrivateRoute;
