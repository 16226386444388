import { validateHtmlMarkUp } from '../../helpers/commonFunction';
import validator from './../../helpers/checkValidations';

export function changeHandler(event) {
  let error = {};
  let misMatchError = [];
  let validationMarkup = true;
  let { id, value } = event.target;
  const isHtmlMarkupValid = validateHtmlMarkUp(value);
  if (isHtmlMarkupValid) {
    validationMarkup = false;
  }
  validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
  return { id, value, misMatchError, error, validationMarkup };
}

export function checkErrorFields(state, error, errorFlag, misMatchError) {
  if (state.paytraceKey == null && state.gateway !== 'multipass') {
    misMatchError.push('Paytrace key required for payment.');
    errorFlag = true;
  }
  if (error.localTimeZoneLabelError && error.localTimeZoneLabelError) {
    misMatchError.push('Local timezone not valid');
    errorFlag = true;
  }

  if (error.emailError) {
    misMatchError.push('Email not valid');
    errorFlag = true;
  }

  if (error.mIdError) {
    misMatchError.push('Merchant Id not valid');
    errorFlag = true;
  }

  if (error.locationNameError) {
    misMatchError.push('Location name not valid');
    errorFlag = true;
  }

  if (error.address1Error) {
    misMatchError.push('Address 1 name not valid');
    errorFlag = true;
  }

  if (error.cityError) {
    misMatchError.push('Select city name');
    errorFlag = true;
  }

  if (error.zipError) {
    misMatchError.push('Select zip code');
    errorFlag = true;
  }

  if (error.stateError) {
    misMatchError.push('Select state');
    errorFlag = true;
  }

  if (error.contactNameError) {
    misMatchError.push('Contact name not valid');
    errorFlag = true;
  }

  if (error.phoneError) {
    misMatchError.push('Phone number not valid');
    errorFlag = true;
  }

  return { misMatchError, errorFlag };
}
