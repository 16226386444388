const isDueDatePassed = (invoice, days = 0) => {
  const currentDate = new Date();
  const modifiedDueDate = new Date(invoice?.dueDate);
  modifiedDueDate.setDate(modifiedDueDate.getDate() + days);
  if (Object.prototype.hasOwnProperty.call(invoice, 'lateFeeApplies')) {
    if (invoice.lateFeeApplies === true) {
      return modifiedDueDate < currentDate;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const customFilterAndSearch = (fieldToSearch, term, rowData) => {
  const searchTerm = term.toLowerCase();
  const fieldValue = fieldToSearch.toString().toLowerCase();

  return fieldValue.indexOf(searchTerm) !== -1;
};

const PAYMENT_STATUS_BUTTON_CONFIG = {
  paid: { text: 'Paid', className: 'btn paidBtn' },
  unpaid: { text: 'Unpaid', className: 'btn PenddingBtn' },
  pending: { text: 'Pending', className: 'btn PenddingBtn' },
  inqueue: { text: 'In Queue', className: 'btn PenddingBtn' },
  failed: { text: 'Failed', className: 'btn DeclinedBtn' },
  declined: { text: 'Declined', className: 'btn DeclinedBtn' },
  visited: { text: 'Visited', className: 'btn PenddingBtn' },
  yet_to_pay: { text: 'Yet to Pay', className: 'btn PenddingBtn' },
  submitted: { text: 'Submitted', className: 'btn SubmittedBtn' },
  deleted: { text: 'Deleted', className: 'btn DeclinedBtn' },
  pastdue: { text: ' Past Due', className: 'btn PastDueBtn' },
};

export { isDueDatePassed, PAYMENT_STATUS_BUTTON_CONFIG, customFilterAndSearch };
