import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { UserDetailById, UserEdit, UserResendActivationLink } from '../../actions/AdminUserAction';
import LoadingOverlay from 'react-loading-overlay';
import queryString from 'query-string';
import { CompanyLocationDataById } from '../../actions/CompanyAction';
import validator from './../../helpers/checkValidations';
import IntegrationDownshift from './../../helpers/multiSelect';
import FormControl from '@material-ui/core/FormControl';
import { locationList } from '../../actions/LocationAction';
import { changeHandler } from './CommonFunctions';
import { getValidationStyle, handleChangeCheckbox, getFeeSettingPermissionTitle } from '../Users/CommonFunction';

class EditUserCompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      userName: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      role: '',
      locationSelected: '',
      sideShow: true,
      isLoading: true,
      misMatchError: [],
      company: {},
      permissions: {
        creatNewUser: false,
        editCustomer: false,
        refundsManual: false,
        exportRecord: false,
        refunds: false,
        allLocaton: false,
        createInvoice: false,
        textToInvoice: false,
        textInvoice: false,
        textNotifications: false,
        ach: false,
        virtualTerminal: false,
        recurring: false,
        feeEdit: false,
        void: false,
        emailNotification: false,
        deleteInvoice: false,
        viewAllTransactions: false,
        notesVisibilityTerminal: false,
        emailVisibilityTerminal: false,
        displayProducts: false,
        displayDashboard: false,
        displaySettings: false,
        displayTickets: false,
        displayLocations: false,
        displayUsers: false,
        displayTransactions: false,
      },
      error: {
        UserNameError: false,
        phoneError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
      },
      marginBottom: '10',
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.getUserData();
    this.getLocationData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.locationSelected !== this.state.locationSelected) {
      this.updateMarginBottom();
      getValidationStyle(!this.state.locationSelected && this.state.isSubmit, this.state.marginBottom);
    }
  }

  updateMarginBottom() {
    const container = this.containerRef.current;
    if (container.scrollHeight > container.clientHeight) {
      this.setState({ marginBottom: container.scrollHeight });
    }
  }

  getUserData() {
    let search = this.props.location.search;
    let param = queryString.parse(search);
    let data = { id: param.users };
    this.setState({ isLoading: true });
    this.props.UserDetailById(data).then((res) => {
      this.setState({ ...res.payload.userData.data, isLoading: false });
      delete this.state.password;
      let data = this.state.location;
      if (data && data.length > 0) {
        this.setState({ locationSelected: Object.keys(data).map((k, _v) => data[k].id) });
      }
    });
  }
  getLocationData() {
    const {
      match: { params },
    } = this.props.props;
    let merchant = params.id;
    let data = { companyId: merchant };
    this.props.locationList(data).then((res) => {
      this.setState({ locationsData: res.payload.locationData.data.response, isLoading: false });
    });
  }

  handleClickMenu(_e) {
    if (this.state.sideShow) this.setState({ sideShow: false });
    else this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    this.getUserData();
  }
  handleChangeCheckbox(event) {
    const permissions = handleChangeCheckbox(event, this.state);
    this.setState({ permissions });
  }
  handleChangeUpdate(event, _t) {
    const { id, value, misMatchError, error, validationMarkup } = changeHandler(event);
    if (!validationMarkup) {
      return false;
    }
    this.setState({
      [id]: value,
      error,
      misMatchError,
      isSubmit: false,
      successMessages: false,
    });
  }

  goBack(e) {
    this.props.handleChangeTab(e, 0);
  }
  handleSubmitResendLink(_e, _t) {
    let successMessages = false;
    if (window.confirm('Are you sure you want to resend the link?')) {
      this.setState({ isLoading: true });
      this.props.UserResendActivationLink(this.state.email).then((result) => {
        if (result !== undefined && result.type === 'USER_EDIT_DATA') {
          window.scrollTo(0, 0);

          setTimeout(() => {
            this.setState({ successMessages: true, isLoading: false });
            // this.goBack(e);
          }, 2000);
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.info.userInfo.data.message, successMessages, isLoading: false });
        }
      });
    }
  }

  updateMenuPermissions() {
    const { role, permissions } = this.state;
    if (role === 3 || role === 4) {
      permissions.displaySettings = false;
      this.setState({ permissions });
    }
  }
  handleSubmitEdit(e, t) {
    let misMatchErrors = [];
    let successMessage = false;
    let state = this.state;
    let errors = {};
    let errorFlag = false;

    this.updateMenuPermissions(); // For Regular users and managers only
    Object.keys(state).map((key, _value) => {
      if (
        key === 'phone' &&
        !this.state.permissions.textToInvoice &&
        !this.state.permissions.textFlex &&
        !this.state.permissions.textInvoice
      ) {
        return false;
      }
      if (validator(key, state[key])) {
        return validator(key, state[key])
          ? ((errors[key + 'Error'] = true), (errorFlag = true))
          : (errors[key + 'Error'] = false);
      } else {
        return state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')
          ? ((errors[key + 'Error'] = true), (errorFlag = true))
          : '';
      }
    });

    if (state.locationSelected.length === 0) {
      misMatchErrors.push(t('LocationRequired'));
      errorFlag = true;
      this.setState({ misMatchError: misMatchErrors });
    }
    if (errorFlag) {
      this.setState({ misMatchError: misMatchErrors, error: errors, successMessages: successMessage });
      return false;
    } else {
      if (window.confirm('Are you sure want to update?')) {
        this.setState({ isLoading: true });
        this.props.UserEdit(this.state).then((result) => {
          if (result !== undefined && result.type === 'USER_EDIT_DATA') {
            window.scrollTo(0, 0);
            this.setState(
              { successMessages: true, misMatchError: misMatchErrors, isLoading: false },
              () => {
                setTimeout(() => {
                  this.goBack(e);
                });
              },
              3000
            );
          }
          if (result !== undefined && result.type === 'USER_ERROR') {
            this.setState({
              misMatchError: this.props.info.userInfo.data.message,
              isLoading: false,
              successMessages: successMessage,
            });
          }
        });
      }
    }
  }
  addLocation = (arr) => {
    this.setState({ locationSelected: arr });
  };

  render() {
    const { t } = this.props;
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
        <Container component="main" maxWidth="md">
          {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
          {this.state.successMessages && this.props.userInfo ? (
            <SuccessMessage successes={[this.props.userInfo.data.message]} />
          ) : (
            ''
          )}
          <form className={classes.form} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.userNameError}
                  id="userName"
                  type="text"
                  labels={t('Username')}
                  value={this.state.userName}
                  width={true}
                  onChange={(e) => this.handleChangeUpdate(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.firstNameError}
                  id="firstName"
                  type="text"
                  labels={t('FirstName')}
                  value={this.state.firstName}
                  width={true}
                  onChange={(e) => this.handleChangeUpdate(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.lastNameError}
                  id="lastName"
                  type="text"
                  labels={t('LastName')}
                  value={this.state.lastName}
                  width={true}
                  onChange={(e) => this.handleChangeUpdate(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="email"
                  type="email"
                  labels={t('Email')}
                  value={this.state.email}
                  width={true}
                  onChange={(e) => this.handleChangeUpdate(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={
                    this.state.permissions.textToInvoice ||
                    this.state.permissions.textFlex ||
                    this.state.permissions.textInvoice
                  }
                  error={
                    this.state.permissions.textToInvoice ||
                    this.state.permissions.textFlex ||
                    this.state.permissions.textInvoice
                      ? this.state.error.phoneError
                      : false
                  }
                  id="phone"
                  type="text"
                  labels={t('Phone')}
                  value={this.state.phone}
                  width={true}
                  onChange={(e) => this.handleChangeUpdate(e, t)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  style={getValidationStyle(
                    !this.state.locationSelected && this.state.isSubmit,
                    this.state.marginBottom
                  )}
                  ref={this.containerRef}
                >
                  <IntegrationDownshift
                    isLocationExists={true}
                    selectedLocations={this.state.location}
                    locationData={this.state.locationsData ? this.state.locationsData : ''}
                    addLocation={this.addLocation}
                  />
                </FormControl>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}></Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  {' '}
                  <h5>Permissions</h5>
                </Grid>
                <Grid container item xs={12}>
                  {this.state.role < 4 ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="creatNewUser"
                          labels="creatNewUsers"
                          checked={this.state.permissions.creatNewUser}
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          value={this.state.creatNewUser}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Create New User</span>
                      </label>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="refunds"
                        labels="refunds"
                        checked={this.state.permissions.refunds}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.refunds}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Refunds General</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="editCustomer"
                        labels="editCustomer"
                        checked={this.state.permissions.editCustomer}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.editCustomer}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Edit Customer</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        value={this.state.refundsManual}
                        id="refundsManual"
                        checked={this.state.permissions.refundsManual}
                        color="primary"
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Refunds Manual</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="allLocaton"
                        labels="allLocaton"
                        checked={this.state.permissions.allLocaton}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.allLocaton}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>All Location</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="createInvoice"
                        labels="createInvoice"
                        checked={this.state.permissions.createInvoice}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.createInvoice}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Invoicing</span>
                    </label>
                  </Grid>
                  {this.state.company.ACH && (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="ach"
                          labels="ach"
                          checked={this.state.permissions.ach}
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          value={this.state.ach}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>ACH </span>
                      </label>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="virtualTerminal"
                        labels="virtualTerminal"
                        checked={this.state.permissions.virtualTerminal}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.virtualTerminal}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Virtual Terminal</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="recurring"
                        labels="recurring"
                        checked={this.state.permissions.recurring}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.recurring}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Recurring Billing</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="exportRecord"
                        checked={this.state.permissions.exportRecord}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.exportRecord}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Export Records</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="feeEdit"
                        checked={this.state.permissions.feeEdit}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.feeEdit}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>{getFeeSettingPermissionTitle(this.state.role)}</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="void"
                        labels="void"
                        checked={this.state.permissions.void}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.void}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Void</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        checked={this.state.permissions.emailNotification}
                        id="emailNotification"
                        labels="emailNotification"
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.emailNotification}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>{t('emailNotifications')}</span>
                    </label>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        checked={this.state.permissions.deleteInvoice}
                        id="deleteInvoice"
                        labels="deleteInvoice"
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.deleteInvoice}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>{t('DeleteInvoice')}</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        checked={this.state.permissions.viewAllTransactions}
                        id="viewAllTransactions"
                        labels="viewAllTransactions"
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.permissions.viewAllTransactions}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>View All Transactions</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="textToInvoice"
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          value={this.state.permissions.textToInvoice}
                          color="primary"
                          checked={this.state.permissions.textToInvoice}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Texting</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ marginLeft: 10 }}>
                      <label>
                        <Checkbox
                          id="textNotifications"
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          value={this.state.permissions.textNotifications}
                          checked={this.state.permissions.textNotifications}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Text Notifications</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ marginLeft: 10 }}>
                      <label>
                        <Checkbox
                          checked={this.state.permissions.textInvoice}
                          id="textInvoice"
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          value={this.state.permissions.textInvoice}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Text Invoice</span>
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <h5>Terminal Field Display</h5>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="emailVisibilityTerminal"
                        checked={this.state.permissions.emailVisibilityTerminal}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.emailVisibilityTerminal}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Email</span>
                    </label>
                    <label>
                      <Checkbox
                        id="notesVisibilityTerminal"
                        checked={this.state.permissions.notesVisibilityTerminal}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.notesVisibilityTerminal}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Notes</span>
                    </label>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <h5>Main Menu Display</h5>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="displayDashboard"
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        checked={this.state.permissions.displayDashboard}
                        value={this.state.permissions.displayDashboard}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Dashboard</span>
                    </label>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="displayProducts"
                        checked={this.state.permissions.displayProducts}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.permissions.displayProducts}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Products</span>
                    </label>
                  </Grid>
                </Grid>
                {this.state.role === 2 ? (
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displaySettings"
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          checked={this.state.permissions.displaySettings}
                          value={this.state.permissions.displaySettings}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Settings</span>
                      </label>
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="displayTickets"
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        checked={this.state.permissions.displayTickets}
                        value={this.state.permissions.displayTickets}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Tickets</span>
                    </label>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="displayLocations"
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        checked={this.state.permissions.displayLocations}
                        value={this.state.permissions.displayLocations}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Locations</span>
                    </label>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="displayTransactions"
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        checked={this.state.permissions.displayTransactions}
                        value={this.state.permissions.displayTransactions}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Transactions</span>
                    </label>
                  </Grid>
                </Grid>
                {this.state.role === 2 || this.state.role === 3 ? (
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displayUsers"
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          checked={this.state.permissions.displayUsers}
                          value={this.state.permissions.displayUsers}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Users</span>
                      </label>
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>

              <Buttons
                id="signUp"
                type="button"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => this.handleSubmitEdit(e, t)}
                text={t('Update')}
              />
              <Buttons
                className={classes.ml_2}
                variant="contained"
                color="secondary"
                text={t('Back')}
                onClick={(e) => this.goBack(e)}
              />
              <Buttons
                className={classes.ml_2}
                variant="contained"
                color="primary"
                text={t('ResendLink')}
                onClick={(e) => this.handleSubmitResendLink(e, t)}
              />
            </Grid>
          </form>
        </Container>
      </LoadingOverlay>
    );
  }
}

EditUserCompanyDetails.propTypes = {
  t: PropTypes.func,
  UserDetailById: PropTypes.func,
  match: PropTypes.any,
  props: PropTypes.any,
  locationList: PropTypes.func,
  location: PropTypes.any,
  info: PropTypes.any,
  handleChangeTab: PropTypes.func,
  UserResendActivationLink: PropTypes.func,
  UserEdit: PropTypes.func,
  userInfo: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    userInfo: state.AdminUserReducer.editInfo,
  };
}

export default connect(mapStateToProps, {
  UserDetailById,
  locationList,
  UserEdit,
  UserResendActivationLink,
  CompanyLocationDataById,
})(withTranslation()(withRouter(EditUserCompanyDetails)));
