import { ActionType } from './../constants';
import { DataApi, DataGetApi } from './../helpers/PostDataApi';
import { config } from './../constants';
import { addJobToPollingList } from '../helpers/commonFunction';

export const customerSyncJobCreationStart = () => ({
  type: ActionType.CUSTOMER_SYNC_JOB_CREATION_START,
});

export const customerSyncJobCreationSuccess = (jobData) => {
  return {
    type: ActionType.CUSTOMER_SYNC_JOB_CREATION_SUCCESS,
    payload: { jobData },
  };
};

export const closeJobCreationNotificationAlert = () => {
  return {
    type: ActionType.CLOSE_JOB_CREATION_NOTIFICATION_ALERT,
    payload: { displayJobCreationNotification: false },
  };
};

export const customerSyncJobCreationError = (error) => ({
  type: ActionType.CUSTOMR_SYNC_JOB_CREATION_ERROR,
  payload: error,
});

export const createCustomerSyncJob = (data, csrf = null, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let { token } = user;
  return (dispatch) => {
    dispatch(customerSyncJobCreationStart());
    return DataApi(data, config.BASEURL + 'jobs/create', token, true, false, csrf, paytraceCredentials).then(
      (response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          // store the jobid in localstorage createdJobsList.
          addJobToPollingList(response.data.response._id);
          return dispatch(
            customerSyncJobCreationSuccess({
              message: response.data?.message,
              success: response?.data.success,
              ...response.data.response,
            })
          );
        } else {
          return dispatch(customerSyncJobCreationError(response.data));
        }
      }
    );
  };
};
