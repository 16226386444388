import axios from 'axios';
import config from '../constants/config';
// const user = JSON.parse(localStorage.getItem('user'))

// const paytraceCredentials = { 'userName': user.company.paytraceUserName, 'password': user.company.paytracePassword, 'integratorId': user.company.paytraceIntegratorId }
axios.interceptors.request.use((config) => {
  if (!config.url.includes('api.bincodes.com')) {
    let locationPaydetails = localStorage.getItem('locationPaydetails');
    if (locationPaydetails !== 'undefined') {
      locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
      if (locationPaydetails?.gateway === 'multipass') {
        config.headers.multipassdeviceid = locationPaydetails.multipassCredentials.multipassDeviceId;
        config.headers.transactionkey = locationPaydetails.multipassCredentials.transactionKey;
        config.headers.gateway = 'multipass';
      } else {
        config.headers.gateway = 'paytrace';
      }
      return config;
    }
  }

  return config;
});

export const DataApi = (
  data = null,
  apiURL = null,
  token = null,
  isHeader = false,
  isMultipart = false,
  csrf = null,
  paytraceCredentials = null
) => {
  let headers = {
    'Content-Type': isMultipart ? 'multipart/form-data' : 'application/json',
    Authorization: 'Bearer ' + token,
    ...csrf,
    ...paytraceCredentials,
  };
  if (isHeader) {
    return axios
      .post(apiURL, data, { headers })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  } else {
    return axios
      .post(apiURL, data)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }
};
export const DataPutApi = (
  apiURL = null,
  paramData,
  token = null,
  paytraceCredentials = null,
  contentType = 'x-www-form-urlencoded'
) => {
  return axios
    .put(apiURL, paramData, {
      headers: {
        'Content-Type': 'application/' + contentType,
        Authorization: 'bearer ' + token,
        ...paytraceCredentials,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
export const DataGetApi = (apiURL = null, paramData, token = null, gatewayCredentails = null) => {
  return axios
    .get(apiURL, {
      params: paramData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + token,
        ...gatewayCredentails,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const checkCreditOrDebit = (apiURL = null, apiKey, bin) => {
  return axios
    .get(apiURL + `?format=json&api_key=${apiKey}&bin=${bin}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const getJobsStatus = async (jobIds) => {
  try {
    let user = JSON.parse(localStorage.getItem('user'));
    let token = user.token;
    const jobsStatus = await DataGetApi(config.BASEURL + `jobs/status`, { jobIds }, token);
    return jobsStatus.data.response;
  } catch (error) {
    console.log('failed to get jobs status', error);
  }
};
