import { ActionType } from './../constants';
import { DataApi, DataGetApi, DataPutApi } from './../helpers/PostDataApi';
import { config } from './../constants';
import { isEqual } from 'lodash';

/**
 * user info Begin Action
 */
export const userStart = () => ({
  type: ActionType.USER_START,
});

/**
 * user info Success Action
 */
export const userSuccess = (userData) => {
  return {
    type: ActionType.USER_SUCCESS,
    payload: { userData },
  };
};

export const defaultLocationSuccess = (locationData) => {
  return {
    type: ActionType.DEFAULT_LOCATION_SUCCESS,
    payload: { defaultLocation: locationData.defaultLocation },
  };
};
/**
 * user info Success Action
 */
export const userEditData = (userData) => {
  return {
    type: ActionType.USER_EDIT_DATA,
    payload: { userData },
  };
};
/**
 * fetch info Success Action
 */
export const fetchSuccess = (userData) => {
  return {
    type: ActionType.FETCH_SUCCESS,
    payload: { userData },
  };
};

export const setLocationOnSuccess = (userData) => {
  if (
    Object.prototype.hasOwnProperty.call(userData, 'defaultLocation') &&
    Object.prototype.hasOwnProperty.call(userData, 'location') &&
    userData.defaultLocation !== null
  ) {
    let locationIds = userData.location.filter((curr) => {
      return curr._id == userData.defaultLocation;
    });
    return {
      type: ActionType.SET_LOCATION,
      payload: locationIds[0],
    };
  } else {
    return {
      type: ActionType.SET_All_LOCATION,
    };
  }
};

/**
 * user info Failure Action
 */
export const userError = (error) => ({
  type: ActionType.USER_ERROR,
  payload: { error },
});

/**
 * user driver info
 */
export const addUser = (data) => {
  return (dispatch) => {
    dispatch(userStart());
    return DataApi(data, config.BASEURL + 'userAdd')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const addSubAdmin = (data) => {
  return (dispatch) => {
    dispatch(userStart());
    return DataApi(data, config.BASEURL + 'addSubAdmin')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const regularUserAdd = (data) => {
  return (dispatch) => {
    dispatch(userStart());
    return DataApi(data, config.BASEURL + 'regularUserAdd')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const commonAction = (data, api) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(userStart());
    return DataGetApi(config.BASEURL + api, data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const adminUserList = (data) => {
  return commonAction(data, 'adminUserList');
};

export const getUserList = (data) => {
  return commonAction(data, 'getUserList');
};

export const adminUserTableList = (data) => {
  return commonAction(data, 'adminUserTableList');
};

export const subAdminUserList = (data) => {
  return commonAction(data, 'subAdminUserList');
};

export const initializeData = (data) => {
  let token = JSON.parse(localStorage.getItem('user')).token;

  let locationId = '';
  if (localStorage.getItem('locationArrIds') !== null) {
    locationId =
      JSON.parse(localStorage.getItem('locationArrIds')).length > 1
        ? null
        : JSON.parse(localStorage.getItem('locationArrIds'))[0];
  }
  if (locationId === null) {
    locationId = JSON.parse(localStorage.getItem('locationArrIds'))[0];
  }

  return {
    token: token,
    locationId: locationId,
  };
};

export const UserDetailById = (data) => {
  let { token, locationId } = initializeData(data);
  let user = JSON.parse(localStorage.getItem('user'));

  return (dispatch) => {
    dispatch(userStart());
    return DataGetApi(config.BASEURL + 'adminUserDetailById', { ...data, locationId: locationId }, token)
      .then((response) => {
        if (response.status === 200) {
          if (
            response.company?.customFieldsEnabled &&
            response.company?.customFields?.find((customField) => customField?.value == 'jobId')
          ) {
            localStorage.setItem('invoiceLabel', response.company?.customFields.find((cf) => cf.value).value);
          } else {
            localStorage.setItem('invoiceLabel', 'Invoice');
          }

          if (response?.data?.permissions && user) {
            const currentPermissions = user.permissions || {};
            const newPermissions = response.data.permissions;
            if (!isEqual(currentPermissions, newPermissions)) {
              user.permissions = newPermissions;
              localStorage.setItem('user', JSON.stringify(user));
            }
          }
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchDetails = (data) => {
  let { token, locationId } = initializeData(data);
  return DataGetApi(config.BASEURL + 'adminUserDetailById', { ...data, locationId: locationId }, token)
    .then((response) => {
      if (response.status === 200) {
        return fetchSuccess(response.data);
      } else {
        return userError(response);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setDetails = (data) => {
  if (data) {
    return fetchSuccess(data);
  } else {
    return userError(data);
  }
};

export const adminUserProfile = (data) => {
  return (dispatch) => {
    dispatch(userStart());
    return DataApi(data, config.BASEURL + 'adminUserProfile')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const UserEdit = (data) => {
  return (dispatch) => {
    return DataApi(data, config.BASEURL + 'adminUserEdit')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(userEditData(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const UserResendActivationLink = (email) => {
  return (dispatch) => {
    dispatch(userStart());
    return DataApi({ email: email }, config.BASEURL + 'resendUserActivationLink')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(userEditData(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const adminUserDelete = (data) => {
  return (dispatch) => {
    dispatch(userStart());
    return DataApi(data, config.BASEURL + 'adminUserDelete')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const adminUserStatus = (data) => {
  return (dispatch) => {
    // dispatch(userStart());
    return DataApi(data, config.BASEURL + 'adminUserStatus')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const UserDetailByLink = (data) => {
  return (dispatch) => {
    dispatch(userStart());
    return DataGetApi(config.BASEURL + 'adminUserDetailByLink', data)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const CustomerUserDetailByLink = (data) => {
  return DataGetApi(config.BASEURL + 'customerUserDetailByLink', data)
    .then((response) => {
      if (response.status === 200 && response.data.statusCode === 200) {
        return userSuccess(response);
      } else {
        return userError(response);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const LocationDetailByLink = (data) => {
  console.log(data, 'data');
  return DataGetApi(config.BASEURL + 'locationDetailById', data)
    .then((response) => {
      if (response.status === 200 && response.data.statusCode === 200) {
        return userSuccess(response);
      } else {
        return userError(response);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const CustomerCreateUserDetailByLink = (data) => {
  return DataApi(data, config.BASEURL + 'customerUserCreate', null, true)
    .then((response) => {
      if (response.status === 200 && response.data.statusCode === 200) {
        return userSuccess(response);
      } else {
        return userError(response);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const CustomerUpdateUserDetailByLink = (data) => {
  return DataApi(data, config.BASEURL + 'customerUserEdit', null, true)
    .then((response) => {
      if (response.status === 200 && response.data.statusCode === 200) {
        return userSuccess(response);
      } else {
        return userError(response);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const userSettings = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  let locationId = '';
  if (localStorage.getItem('locationArrIds') !== null) {
    locationId =
      JSON.parse(localStorage.getItem('locationArrIds')).length > 1
        ? null
        : JSON.parse(localStorage.getItem('locationArrIds'))[0];
  }
  return (dispatch) => {
    dispatch(userStart());
    return DataApi({ ...data, locationid: locationId }, config.BASEURL + 'userSettings', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          user.settings = response.data.response.settings;
          localStorage.setItem('user', JSON.stringify(user));
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const setViewPreferences = (viewPreferences) => {
  //userid, location, company,
  //'/userViewSettings:/company:/user'
  const user = JSON.parse(localStorage.getItem('user'));
  return (dispatch) => {
    return DataPutApi(
      config.BASEURL + `userViewPreferences/${user.company._id}/${user._id}`,
      viewPreferences,
      user.token,
      undefined,
      'json'
    ).then((response) => {
      if (response.status === 200 && response.data.statusCode === 200) {
        return dispatch({
          type: ActionType.SAVE_PREFERENCES_SUCCESS,
          payload: response,
        });
      } else {
        console.log('status!=200', response);
      }
    });
  };
};
export const fetchUserPreferences = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return (dispatch) => {
    return DataGetApi(
      config.BASEURL + `userViewPreferences/${user.company._id}/${user._id}`,
      undefined,
      user.token
    ).then((response) => {
      return dispatch({
        type: ActionType.FETCH_USER_PREFERENCES_SUCCESS,
        payload: response.data.response,
      });
    });
  };
};
export const settingDataByCompanyId = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(userStart());
    return DataGetApi(config.BASEURL + 'settingDataByCompanyId', data, token)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getUserSettingsByLocationId = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(userStart());
    return DataApi(
      { company: user.company, user: user._id, location: data.location },
      config.BASEURL + 'getUserSettingsByLocationId',
      token,
      true
    )
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(userSuccess(response));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
/**
 * update default location
 *
 * @param {Object} data
 */
export const updateDefaultLocation = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  user.defaultLocation = data.defaultLocation;
  localStorage.setItem('user', JSON.stringify(user));
  return (dispatch) => {
    dispatch(userStart());
    return DataApi(data, config.BASEURL + 'updateDefaultLocation', user.token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          user.settings = response.data.response.settings;
          localStorage.setItem('user', JSON.stringify(user));
          return dispatch(defaultLocationSuccess(data.defaultLocation));
        } else {
          return dispatch(userError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const markTransaction = (transactionUpdateObj) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return (dispatch) => {
    return DataPutApi(
      config.BASEURL + `markTransaction/${transactionUpdateObj?.txnid}`,
      { mark: transactionUpdateObj.mark },
      user.token,
      undefined,
      'json'
    )
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch({
            type: ActionType.TRANSACTIOIN_MARK_SUCCESS,
            payload: response,
          });
        } else {
          console.log('status!=200', response);
        }
      })
      .catch((err) => {
        console.log('err while marking transaction', err);
      });
  };
};
